import { produce } from 'immer'
import { Reducer } from 'redux'

import {
  AUTHENTICATION_EXPIRED,
  AuthActionTypes,
  AuthState,
  SIGN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  UPDATE_AUTH_HEADERS,
} from './types'

export const INITIAL_STATE = {
  isLoading: false,
  isSigned: false,
  isAuthenticationExpired: false,
  signFailureMessage: '',
  headers: {
    uid: '',
    client: '',
    accessToken: '',
    tokenType: '',
    expiry: '',
  },
}

const auth: Reducer<AuthState, AuthActionTypes> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SIGN_IN_SUCCESS: {
        draft.isSigned = true
        draft.isLoading = false
        draft.headers = action.payload.headers
        draft.isAuthenticationExpired = false
        draft.signFailureMessage = ''
        break
      }
      case SIGN_IN_REQUEST: {
        draft.isLoading = true
        draft.headers = null
        draft.isSigned = false
        draft.signFailureMessage = ''
        break
      }
      case SIGN_FAILURE: {
        draft.isSigned = false
        draft.isLoading = false
        draft.headers = null
        draft.signFailureMessage = action.payload || ''
        break
      }
      case SIGN_OUT: {
        draft.isSigned = false
        draft.isLoading = false
        draft.headers = null
        draft.isAuthenticationExpired = false
        draft.signFailureMessage = ''
        break
      }
      case AUTHENTICATION_EXPIRED: {
        draft.isSigned = true
        draft.isLoading = false
        draft.headers = null
        draft.isAuthenticationExpired = true
        draft.signFailureMessage = ''
        break
      }
      case UPDATE_AUTH_HEADERS: {
        draft.headers = action.payload
        break
      }

      default:
    }
  })

export default auth
